@import "~bootstrap/scss/bootstrap.scss";
@import "./animations";
@import "./index";
@import "./stars";
@import "./typography";

.under-construction {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 3em;
  font-weight: 800;

  /* Fancy text effects */
  color: #fff;
  /* White text color */
  background: -webkit-linear-gradient(#f00, rgb(26, 26, 26));
  /* Animated gradient background */
  background-clip: text;
  /* Clip gradient to the text shape */
  -webkit-text-fill-color: transparent;
  /* Make text transparent to show gradient */
  animation: glowingText 3s infinite alternate;
  /* Add glowing animation */
}

@keyframes glowingText {
  from {
    text-shadow: 0 0 10px #fff;
    /* Initial glow */
  }

  to {
    text-shadow: 0 0 20px #fff;
    /* Increased glow */
  }
}
