html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bgstyle {
  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
  /*background: linear-gradient(
    136deg,
    #4484ce,
    #1ad7c0,
    #ff9b11,
    #9b59b6,
    #ff7f7f,
    #ecf0f1
  );*/

  background-size: 1200% 1200%;
  -webkit-animation: BackgroundAnimation 70s ease infinite;
  -moz-animation: BackgroundAnimation 70s ease infinite;
  -o-animation: BackgroundAnimation 70s ease infinite;
  animation: BackgroundAnimation 70s ease infinite;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}
//COLORS
//EF233C
//D90329
.navbar-transparent {
  background-color: rgb(12 12 12);
  .navbar-toggler {
    background-color: white;
  }

  .navbar-nav .nav-link.active {
    color: #ef233c;
    font-weight: 800;
  }
  a.nav-link {
    &:hover {
      color: #d90329;
    }
  }
  a {
    color: rgb(213, 212, 212);
    font-weight: 500;
  }
}
.navbar-white {
  background-color: rgb(239 35 60 / 60%);
  .navbar-nav .nav-link.active {
    font-weight: 800;
  }
  a {
    color: black;
  }
}
.brand {
  color: #363636 !important;
}
// .nav-item {
//   transition: color, 0.2s;
// }
.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    background-color: white !important;
    outline: none;
  }
}

.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
  &:hover {
    color: rgb(255, 255, 255);
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}
.main-body {
  display: flex;
  flex: 1;
  /* Adjusted to 'space-between' for equal spacing */
  height: 90vh;
  flex-direction: column;
  text-align: left;
  padding: 0;
  justify-content: space-evenly;
  gap: 10px;

  .main-body-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .main-body-section-contents {
      display: flex;
      height: 50vh;
      flex-direction: column;
      justify-content: space-around;
    }

    .main-body-animation {
      display: flex;
      height: 520px;
    }
  }

  .main-body-sub-section {
    display: flex;
  }
  .action {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .action-cta {
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    .hashtags {
      display: flex;
      gap: 30px;
      font-size: 16px;

      .hashtags-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .hashtag-section {
      }
    }
  }
}
.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition:
      transform 0.3s,
      box-shadow 0.3s;
  }
}
.skills-tabs {
  flex-grow: 1;
}
.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}
.progress-bar-animation {
  @extend .progress;
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}

.about-container {
  display: flex;
  justify-content: space-evenly;

  .about-animation {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-content {
    text-align: right;
  }
}

.mission-container {
  padding: 20px;
  width: 100%;
  // background-image: url("/medical-banner-with-doctor-holding-tablet.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  // background: linear-gradient(to right, rgba(86, 84, 84, 0.4),rgba(0,0,0,0));

  .mission-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .mission-header {
      display: flex;
      flex-direction: column;
    }

    .mission-info {
      display: flex;
      align-items: center;

      .mission-info-content {
        flex: 0 1 70%;
      }

      .mission-animation {
        // margin-bottom: 10px;
        flex: 0 1 30%;
      }
    }
  }
}

.poct-solutions-container {
  padding: 20px;
  width: 100%;
  // background-image: url("/medical-banner-with-doctor-holding-tablet.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  // background: linear-gradient(to right, rgba(86, 84, 84, 0.4),rgba(0,0,0,0));

  .mission-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .mission-header {
      display: flex;
      flex-direction: column;
    }

    .mission-info {
      display: flex;
      align-items: center;

      .mission-info-content {
        flex: 0 1 60%;
      }

      .mission-animation {
        margin-bottom: 10px;
        flex: 0 1 40%;
      }
    }
  }

  .poct-solutions-content {
    display: flex;
    flex-direction: column;

    .poct-solutions-items {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 20px;
      gap: 5px;
      overflow: hidden;

      a {
        text-decoration: none;
        /* Removes the underline from links */
        color: inherit;
        /* Makes the link color the same as the surrounding text */
      }

      a:hover,
      a:visited,
      a:active {
        text-decoration: none;
        /* Keeps the underline removed on hover, visited, and active states */
        color: inherit;
        /* Keeps the link color consistent on hover, visited, and active states */
      }

      .poct-solutions-item {
        flex: 1;
        height: 280px;
        color: #ffffff;
        /* Ensuring text is white for better contrast */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        // text-align: center;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px 10px;
        transition:
          transform 0.3s ease,
          box-shadow 0.3s ease;
      }

      .item1 {
        background-image: linear-gradient(to right, #b53c48, #88272f);
      }
      .item2 {
        background-image: linear-gradient(to right, #b53c48, #88272f);
      }

      .item3 {
        background-image: linear-gradient(to right, #b53c48, #88272f);
      }

      .poct-solutions-item:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }
      .poct-solutions-item-name {
        font-weight: 700;
        font-size: 20px;
      }
      .poct-solutions-item-link {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}

.solutions-container {
  padding: 10px;
  width: 100%;
  // background-image: url("/medical-banner-with-doctor-holding-tablet.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  // background: linear-gradient(to right, rgba(86, 84, 84, 0.4),rgba(0,0,0,0));

  .solutions-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .solutions-info {
      .solution-header {
        display: flex;
        justify-content: center;
        align-items: center;
        .emergency-animation{
          width: 110px;
        }
      }
      .solutions-items {
        display: flex;
        flex-wrap: wrap;
        /* Allow items to wrap */
        justify-content: space-around;
        /* Maintain space around items */
        gap: 20px;
        /* Space between items */

        .solutions-item {
          flex: 0 1 calc(50% - 20px);
          /* Each item takes up to 50% of the container width minus gap */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          /* Optional: Adds shadow for depth */
          border-radius: 10px;
          /* Rounded corners */
          padding: 10px;
          /* Padding inside each item */
          background-color: #fff;
          /* Optional: Background color */
          .solutions-item-name {
            color: #BD202E;
            font-size: 20px;
            font-weight: 700;
            display: flex;
            align-items: center;
          }

          .solutions-item-description {
          }
        }
      }

      .team-animation {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.team-container {
  padding: 20px;
  width: 100%;
  // background-image: url("/medical-banner-with-doctor-holding-tablet.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  // background: linear-gradient(to right, rgba(86, 84, 84, 0.4),rgba(0,0,0,0));

  .team-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .team-heading {
      // animation: neon 3s infinite;
    }

    .team-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      align-items: center;
      // flex-column justify-content-center text-center align-items-center
      .team-members {
        display: flex;
        justify-content: space-around;
        column-gap: 20px;

        .team-member {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .team-member-photo {
            border-radius: 40px;
            background: rgb(154, 24, 33);
            background: linear-gradient(83deg, rgba(154, 24, 33, 1) 0%, rgba(65, 26, 44, 1) 100%);
            // width: 256px;
            // height: 326px;
          }

          .team-member-info {
            a {
              text-decoration: none;
              /* Removes the underline from links */
              color: inherit;
              /* Makes the link color the same as the surrounding text */
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            .team-member-name {
              font-size: 20px;
              font-weight: 700;
            }

            .team-member-role {
              color: #bd202f;
              text-decoration: solid;
              font-weight: 600;
              font-style: italic;
              border: 2px solid #bd202f;
              border-radius: 5px;
              padding: 7px;

              display: flex;
              align-items: center;
              gap: 10px;
            }

            .team-member-description {
              margin-top: 10px;
              text-align: center;
            }
          }
        }
      }

      .team-animation {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.navbar-brand {
  font-weight: 700;
}

.footer {
  background-color: #f5f5f5;
  .footer-container {
    display: flex;
    justify-content: space-evenly;
    .footer-info {
      display: flex;
      flex-direction: column;
      column-gap: 5px;
      align-items: center;
      justify-content: space-evenly;

      .footer-container-new {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        justify-content: space-evenly;
      }

      .footer-info-section {
        .footer-info-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .footer-contact {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        text-align: center;

        .footer-info-content {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
      }
      .footer-info-heading {
        font-size: 32px;
        margin-bottom: 15px;
      }
    }
  }

  .footer-rights {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    column-gap: 4px;
  }
}

.icon-button {
  margin-bottom: 10px;
}

.icon-button i {
  color: #ea4335;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.5rem;
  height: 2.9rem;
  line-height: 2.9rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.9rem;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: 0.2s ease-in;
}
.getintouch {
  display: flex;
  flex-direction: column;
  align-items: center;
  .getintouch-message {
    width: 60%;
  }
  .getintouch-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    .getintouch-heading-animation {
      display: flex;
      height: 128px;
    }
  }

  .getintouch-submessage-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 5px;

    .getintouch-submessage {
      font-size: 20px;
      font-weight: bold;
    }

    .getintouch-animation {
      display: flex;
      height: 48px;
    }
  }
}

/* Media Query */
@media (max-width: 1380px) {
}

@media (max-width: 768px) {
  .navbar-white {
    background-color: rgb(239 35 60 / 60%);
    // transition: background-color 0.2s;

    a {
      color: #232324;
      font-weight: 800;
    }
  }
  .main-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* Adjusted to 'space-between' for equal spacing */
    height: 90vh;
    /* Use 100vh for full viewport height, or 100% if inside another container */
    text-align: left;
    .main-body-section {
      .main-body-animation {
        display: none;
      }
    }

    .action {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      gap: 50px;
      height: 100vh;

      .hashtags {
        font-size: 14px;
      }
    }
  }
  .mission-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .mission-header {
      display: flex;
      flex-direction: column;
    }

    .mission-info {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mission-info-content {
        flex: 0 1 100%;
      }

      .mission-animation {
        flex: 0 1 100%;
      }
    }
  }
  .solutions-items {
    flex-direction: column;
    .solutions-item {
      flex: 0 1;
    }
  }

  .team-content {
    .team-info {
      text-align: center !important;
      .team-members {
        text-align: left;
        flex-direction: column;
        row-gap: 30px;
      }
    }
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 20px;
  }

  .about-container {
    flex-direction: column;
    .about-animation {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .about-content {
      text-align: center;
    }
  }
  .poct-solutions-container {
    flex-direction: column;
    .poct-solutions-content {
      padding: 0px;
      .poct-solutions-items {
        .poct-solutions-item {
          min-height: 220px;
          width: 100%;
        }
        // display: flex;
        flex-direction: column;
        // justify-content: start;
        // padding: 0px;
        // align-items: start;
        // gap: 5px;
        // overflow: hidden;
      }
    }
  }
  .getintouch {
    .getintouch-heading {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .getintouch-heading-animation {
        display: flex;
        justify-content: center;
        height: 92px;
      }
    }
    .getintouch-message {
      width: 90%;
    }
  }

  .footer-contact {
    margin: 20px 0;
  }
}

@media (max-width: 320px) {
}
