.contact-form{
  .contact-form-heading{
    font-size: 24px;
    padding-bottom: 20px;
  }

.form-container {
  display: flex;
  flex-direction: column;
  width: 300px;
  /* Adjust width as needed */
  margin: 0 auto;
  text-align: left;

  label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
    /* Label color */

    &:after {
      content: attr(data-required);
      color: red;
      /* Required indicator color */
    }
  }

  input,
  textarea,
  select,
  button {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;

    &:focus {
      outline: none;
      border-color: dodgerblue;
      /* Adjust focus border color */
    }
  }

  textarea {
    height: 100px;
    /* Adjust textarea height as needed */
  }

  select {
    appearance: none;
    /* Remove default arrow */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    padding-right: 30px;
    /* Adjust to accommodate the arrow */
  }

  button {
    background-color: #090909;
    /* Button background color */
    color: white;
    /* Button text color */
    cursor: pointer;

    &:hover {
      background-color: #bd202f;
      /* Adjust button hover color */
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.submit-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: censter;

  p {
    font-weight: 800;
  }
}
}